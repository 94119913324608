import { NAS_IO_META_PROPS } from '@/features/OnboardingPage/constants';
import PageMetaTags from './PageMetaTags';

const metaData = {
  ...NAS_IO_META_PROPS,
  url: process.env.NEXT_PUBLIC_SITE_BASE_URL
};

const PortalPagesMetaTags = () => {
  return <PageMetaTags {...metaData} />;
};

export default PortalPagesMetaTags;
