import React from 'react';
import CloseIcon from '@/components/common/Icons/CloseIcon/CloseIcon';
import { useStartCta } from '@/hooks/useStartCta';

/**
 * Modal that is shown when the user is not a member of any community
 * @param {function} onClose - Callback function to close the modal
 * @returns {JSX.Element}
 * @constructor
 */
export const NoCommunityModal = ({ onClose }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const { link } = useStartCta();
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="fixed flex flex-col items-center justify-center top-0 left-0 w-full h-full z-50 backdrop-blur-md bg-black bg-opacity-50"
        onClick={handleBackdropClick}>
        <div className="relative max-w-[90%] md:max-w-500 rounded-28 bg-white-default shadow-npl-styles-shadow-02">
          <img
            src="/images/yellow-wave.png"
            alt="No community illustration"
            className="rounded-t-28"
          />
          <button
            className="absolute top-10 right-10 w-40 h-40 flex items-center justify-center rounded-full bg-black bg-opacity-50"
            onClick={onClose}>
            <CloseIcon fill="#F5F5F5" />
          </button>
          <div className="py-32 px-24">
            <h3 className="font-semibold text-heading-md text-npl-neutral-light-12">
              It seems like you do not have any communities
            </h3>
            <p className="pt-8 font-normal text-body-md text-npl-neutral-light-12 text-opacity-65">
              Try another email or start your own community today!
            </p>
            <div className="mt-24 flex items-center justify-end gap-12">
              <a
                href={link}
                className="block rounded-full py-14 px-24 bg-primary text-npl-neutral-light-12 text-14 font-medium">
                Start a Community
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
