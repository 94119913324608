const { trackGAEvent } = require('@/utility/analytics');
const {
  MEMBER_LOGIN_EVENT,
  CMP_LOGIN_EVENT
} = require('@/utility/analyticsConsts');
const { isMemberOnly } = require('@/utility/user');

export const sendLoginDataToAnalytics = (user) => {
  if (!user) return;
  const analyticsParams = {
    email: user?.email
  };

  const eventId = isMemberOnly(user)
    ? MEMBER_LOGIN_EVENT
    : CMP_LOGIN_EVENT;
  trackGAEvent(eventId, analyticsParams);
};
