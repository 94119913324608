import {
  loginFormSchema,
  shortLogoImageData
} from '../LoginForm/constants';
import { parse } from 'query-string';
import Button from '@/components/common/Button';
import FloatingLabelInput from '@/components/common/Form/FloatingLabelInput';
import LoadingCircle from '@/components/common/LoadingCircle';
import { NO_COMMUNITY_ERROR } from '@/pages/login/constants';
import NextImage from '@/components/common/NextImage';
import SocialLogins from '@/components/common/SocialLogins';
import classNames from 'classnames';
import config from '@/utility/config';
import style from './LoginForm.module.scss';
// import { useAdminAuthContext } from '@/contexts/AdminAuthContext';
import { useEffect, useState } from 'react';
import { validate } from '@/utility/validation';

const className = 'c-LoginPage-LoginForm';

const LoginForm = ({
  isLoading,
  loginError,
  login,
  handleSocialAuth,
  socialLoginError
}) => {
  // const { handleSocialAuth, socialLoginError } = useAdminAuthContext();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  const onLogin = async (e) => {
    e.preventDefault();

    const isValid = await validate({
      schema: loginFormSchema,
      formData,
      setErrors
    });

    if (!isValid) {
      return;
    }

    const email = formData.email;
    const password = formData.password;
    await login(email, password);
  };

  const onFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const onHandleSocialAuthWithCommunityMandatory = (
    response,
    provider,
    isCommunityAdmin,
    shouldRedirect
  ) => {
    console.log(
      '>> onHandleSocialAuthWithCommunityMandatory ',
      response,
      provider,
      isCommunityAdmin,
      shouldRedirect
    );
    return handleSocialAuth(
      response,
      provider,
      isCommunityAdmin,
      shouldRedirect,
      true
    );
  };

  useEffect(() => {
    const parseObj = parse(window.location.search);

    if (parseObj.email) {
      setFormData((prev) => {
        return { ...prev, email: parseObj.email };
      });
    }
  }, []);

  return (
    <div className={style[className]}>
      <div
        className={style[`${className}__img rounded-tl-4 rounded-bl-4`]}>
        <NextImage {...shortLogoImageData} />
      </div>
      <div className="mt-24 mb-12 font-semibold text-dark-netural10 text-heading-sm xl:mt-32 xl:text-heading-md">
        Login to your community
      </div>
      <div className="mb-24 font-poppins text-label-sm xl:text-label-md">
        {/* <span className="text-light-text">Logging in as a Member? </span>
        <span className="underline">
          <a
            className="font-medium text-gold"
            href={process.env.NEXT_PUBLIC_LEARNER_PORTAL_PATH}>
            Click here
          </a>
        </span> */}
      </div>
      <div>
        <SocialLogins
          isLoading={false}
          handleSocialAuth={onHandleSocialAuthWithCommunityMandatory}
          socialLoginError={socialLoginError}
        />
        <div className={style[`${className}__or`]}>
          <div className={style[`${className}__or__text`]}>OR</div>
        </div>
      </div>
      <form className={style[`${className}__form`]} onSubmit={onLogin}>
        <FloatingLabelInput
          type="email"
          value={formData?.email}
          name="email"
          onChange={onFieldChange}
          label="Email"
          placeholder="Enter your email"
          error={errors?.email}
          required
          focus
          v2
          data-testid="-input-email"
        />
        <div className="mt-16">
          <FloatingLabelInput
            type="password"
            value={formData?.password}
            name="password"
            onChange={onFieldChange}
            placeholder="Enter Password"
            eyeIcon
            label="Password"
            error={errors?.password}
            required
            v2
            data-testid="-input-password"
          />
        </div>
        <div
          className={classNames(
            style[`${className}__form__link`],
            'mt-4'
          )}>
          <a
            title="Forgot Password"
            href={`${config.memberPortalPath}/forget-password`}
            rel="noreferrer"
            target="_blank"
            data-testid="forgot-password">
            Forgot your password?
          </a>
        </div>
        {isLoading ? (
          <LoadingCircle />
        ) : (
          <Button
            displayType="primary"
            type="submit"
            fluid
            data-testid="login-button">
            Login
          </Button>
        )}
        {loginError && loginError !== NO_COMMUNITY_ERROR && (
          <div className={style[`${className}__error-message`]}>
            {loginError}
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
