import { communityUserRoles } from './loginConstants';

export const isMemberOnly = (user) => {
  const userRolesData = Object.keys(user?.roles);
  const isMemberOnly =
    userRolesData.length === 1 &&
    userRolesData.includes(communityUserRoles.MEMBER);

  return isMemberOnly;
};
