export const SAVE_AND_EXIT = 'Save and Exit';
export const BANNER_LEFT_TITLE = 'Bring people together';
export const SLIDER_LABEL = 'How many members are you expecting?';
export const CHOOSE_CHAT_LABEL = 'Connect a platform to your community';
export const VERIFY_PHONE_NUMBER_LABEL = 'Verify your phone number';
export const VERIFY_PHONE_NUMBER_SUBTITLE =
  'We’ll send you a confirmation code';
export const CONNECT_EXISTING_CHAT_TITLE = 'Connect an existing chat';
export const COMMUNITY_SET_UP_CELEBRATION_TITLE =
  'Your community now has a home!';
export const CREATE_PROFILE_TITLE = 'Create your profile';
export const MOBILE_CREATE_PROFILE_TITLE = 'Create your account';
export const CREATE_PROFILE_SUBTITLE = 'Start customizing your community';
export const OR = 'Or';
export const LOGIN_BUTTON_LABEL = 'Continue';
export const EMAIL_PLACEHOLDER_LABEL = 'Email';
export const EMAIL_PLACEHOLDER = 'john.doe@nas.io';
export const COMPLETE_YOUR_PROFILE_TITLE = 'Complete your profile';
export const WELCOME_BACK_PROFILE_TITLE = 'Welcome back,';
export const MOBILE_WELCOME_BACK_PROFILE_TITLE = 'Log in to your account';
export const WELCOME_BACK_PROFILE_SUBTITLE = 'Log in to continue';
export const FORGOT_YOUR_PASSWORD_LABEL = 'Forgot your password?';
export const NAME_PLACEHOLDER_LABEL = 'Name';
export const PASSWORD_CREATE_PLACEHOLDER = 'Create a password';
export const PASSWORD_PLACEHOLDER = 'Password';
export const REENTER_PASSWORD_PLACEHOLDER = 'Re-enter password';
export const CHOOSE_PRICE_TITLE = 'Select your monetization goal';
// export const WHATSAPP_CHOOSE_PRICE_TITLE =
//   'Choose how others access your WhatsApp group';
export const WHATSAPP_CHOOSE_PRICE_TITLE = CHOOSE_PRICE_TITLE;
export const CHOOSE_PRICE_SUBTITLE = 'You can always change it afterwards';
export const TELEGRAM_CONNECTED_TITLE =
  'Telegram is now successfully connected!';
export const WHATSAPP_CONNECTED_TITLE =
  'WhatsApp is now successfully connected';
export const WHATSAPP_CREATE_GROUP_TITLE = 'Create a new Chat';
export const WHATSAPP_CREATE_GROUP_SUBTITLE =
  'We’ll notify you via email when your group is created';
export const EDIT_DOMAIN_LABEL = 'Edit Domain';
export const COPY_LINK_LABEL = 'Copy Link';
export const VIEW_COMMUNITY_PAGE_LABEL = 'View Public Page';
export const CANCEL_LABEL = 'Cancel';
export const SAVE_LABEL = 'Save';
export const CHOOSE_NFT_TITLE = 'Choose which NFT to allow access';
export const CHOOSE_NFT_SUBTITLE =
  'We only support Ethereum chain (ERC-721) at the moment. ETH ERC-1155 and Solana are Coming Soon!';
export const EDIT_DOMAIN_TITLE = 'Edit your public page URL';
export const CONTINUE_TO_PORTAL_LABEL = 'Continue to portal';
export const TOOLTIP_LABEL =
  'After your community setup is confirmed, you can nominate a bank account for receiving payments in Payout Settings.';
export const MOBILE_CHECK_EMAIL_TITLE = 'Nas.io is best set up on desktop';
// export const PASSWORD_HELP_TEXT = [
//   { text: 'Your password must be have at least:' },
//   { text: '• 8 characters', isNewLine: true },
//   { text: '• 1 uppercase & 1 lowercase character', isNewLine: true },
//   { text: '• 1 number', isNewLine: true }
// ];
export const PASSWORD_HELP_TEXT = [
  { text: 'Your password must be have at least:' },
  { text: '8 characters', isPoint: true },
  { text: '1 uppercase & 1 lowercase character', isPoint: true },
  { text: '1 number', isPoint: true }
];
export const COMMUNITY_SUCCESSFUL_SUBTITLE =
  'Your Public Page is where people can sign up to your community. You can personalize it in the portal.';
export const WHATSAPP_COMMUNITY_SUCCESSFUL_SUBTITLE =
  'Your public page is where people can sign up to join the WhatsApp group. You can personalise it in the portal.';
export const COMMUNITY_DESCRIPTION_PLACEHOLDER = `What is the purpose of your community? Reach new members by telling your audience why they should be excited to join! 

e.g. The best way to begin and grow as a creator is to surround yourself with other creators. That’s how I grew my social media to over 1 million followers and why I’m starting this community you to learn and grow together!`;
export const VERIFY_OTP_SUBTITLE = (phoneNumber) =>
  `Enter the code we've sent via Telegram to ${phoneNumber}:`;

export const HOSTED_BY_SUBTITLE = (hostName) => `Hosted by ${hostName}`;

export const COMMUNITY_SUCCESSFUL_TITLE = (communityName) =>
  `Congrats! ${communityName} is live!`;

export const TERMS_AND_CONDITIONS_PRE_TITLE =
  "By continuing, you agree with Nas.io's ";

export const MEMBERS = 'Members';

export const NAS_EMAIL_SENT_IMAGE = {
  alt: 'Email Check',
  mobileImgProps: {
    src: 'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/nasIO/new_home/png/check_email_banner.png',
    width: 300,
    height: 200
  }
};

export const NASIO_LOGO_IMAGE = {
  alt: ' Logo',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/nasio_logo_white.svg',
    width: 110,
    height: 24
  }
};
export const NAS_IO_META_PROPS = {
  title: 'Make money from your chat groups',
  description:
    'Monetise your WhatsApp group, Telegram group, or your discord server with exclusive Memberships, Courses, Events, and more.',
  imageUrl:
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/jpg/homepage-meta-image-2023.jpg'
};

export const SAMPLE_PEOPLE_IMAGES = [
  {
    alt: 'Sample Person 1',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/sample_person_1.png',
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 2',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/sample_person_2.png',
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 3',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/sample_person_3.png',
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 4',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/sample_person_4.png',
      width: 80,
      height: 80
    }
  },
  {
    alt: 'Sample Person 5',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/sample_person_5.png',
      width: 80,
      height: 80
    }
  }
];

export const SAMPLE_AVATAR_IMAGES = [
  {
    alt: 'Sample Person 1',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_1.png',
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 2',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_2.png',
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 3',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_3.png',
      width: 40,
      height: 40
    }
  },
  {
    alt: 'Sample Person 4',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/sample_avatar_4.png',
      width: 40,
      height: 40
    }
  }
];

export const DEFAULT_LOGO_IMAGE = {
  alt: 'Logo',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/zeneca_image.png',
    width: 80,
    height: 80
  }
};

export const CONNECT_CHAT_IMAGE = {
  alt: 'Connect Chat',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/message_icon.svg',
    width: 80,
    height: 80
  }
};

export const CONNECTION_IMAGE = {
  alt: 'Connection',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/connection.svg',
    width: 80,
    height: 80
  }
};

export const NAS_IO_LOGO = {
  alt: 'Nas IO',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/logo.svg',
    width: 72,
    height: 72
  }
};

export const TELEGRAM_ICON = {
  alt: 'telegram',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/telegram.svg',
    width: 48,
    height: 48
  }
};

export const DISCORD_ICON = {
  alt: 'discord',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/discord-coloured-new.svg',
    width: 44.44,
    height: 44.44
  }
};

export const FACEBOOK_ICON = {
  alt: 'facebook',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/facebook-coloured.svg',
    width: 33.33,
    height: 33.33
  }
};

export const WHATSAPP_ICON = {
  alt: 'whatsapp',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/whatsapp_colored.svg',
    width: 48,
    height: 48
  }
};

export const SLACK_ICON = {
  alt: 'slack',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/slack.svg',
    width: 33.33,
    height: 33.33
  }
};

export const LINKEDIN_ICON = {
  alt: 'linkedin',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/linkedin.svg',
    width: 33.33,
    height: 33.33
  }
};

export const CHAT_PLATFORMS = [
  {
    name: 'whatsapp',
    iconProps: WHATSAPP_ICON,
    label: 'WhatsApp'
  },
  {
    name: 'facebook',
    iconProps: FACEBOOK_ICON,
    label: 'Facebook Group'
  },
  {
    name: 'discord',
    iconProps: DISCORD_ICON,
    label: 'Discord'
  },
  {
    name: 'telegram',
    iconProps: TELEGRAM_ICON,
    label: 'Telegram'
  },
  {
    name: 'linkedin',
    iconProps: LINKEDIN_ICON,
    label: 'LinkedIn Group'
  },
  {
    name: 'slack',
    iconProps: SLACK_ICON,
    label: 'Slack'
  }
];

export const MONETIZAITON_GOAL_PRICING_OPTIONS = [
  {
    id: 'paid',
    label: 'Charge membership fee for my group chat',
    imgSrc:
      'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/superchargev2/membership_goal.png'
  },
  {
    id: 'free',
    label: 'Sell content or event in a free group chat',
    imgSrc:
      'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/superchargev2/Benefit.png'
  }
];

export const PRICING_OPTIONS = [
  {
    id: 'paid',
    label: 'Paid',
    description: 'Charge a fee for access.',
    iconProps: {
      alt: 'Free Icon',
      mobileImgProps: {
        src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/paid.svg',
        width: 30,
        height: 30
      }
    }
  },
  {
    id: 'free',
    label: 'Free',
    description: 'Anyone can join for free.',
    iconProps: {
      alt: 'Free Icon',
      mobileImgProps: {
        src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/free.svg',
        width: 30,
        height: 30
      }
    }
  },
  {
    id: 'nft-gated',
    label: 'NFT-Gated',
    description: 'NFT holders only.',
    iconProps: {
      alt: 'Free Icon',
      mobileImgProps: {
        src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/svg/token.svg',
        width: 30,
        height: 30
      }
    }
  }
];

export const DEFAULT_BACKGROUND_IMAGE = {
  alt: 'Default Background',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/default-background-1.png',
    width: 1920,
    height: 1080
  }
};

export const DEFAULT_COMMUNITY_IMAGE_1 =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/jpg/default-community-logo-blue.jpg';
export const DEFAULT_COMMUNITY_IMAGE_2 =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/jpg/default-community-logo-green.jpg';
export const DEFAULT_COMMUNITY_IMAGE_3 =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/jpg/default-community-logo-orange.jpg';
export const DEFAULT_COMMUNITY_IMAGE_4 =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/jpg/default-community-logo-pink.jpg';
export const DEFAULT_COMMUNITY_IMAGE_5 =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/jpg/default-community-logo-yellow.jpg';

export const RANDOM_COMMUNITY_IMAGE = () => {
  const randomNumber = Math.floor(Math.random() * 5) + 1;
  switch (randomNumber) {
    case 1:
      return DEFAULT_COMMUNITY_IMAGE_1;
    case 2:
      return DEFAULT_COMMUNITY_IMAGE_2;
    case 3:
      return DEFAULT_COMMUNITY_IMAGE_3;
    case 4:
      return DEFAULT_COMMUNITY_IMAGE_4;
    case 5:
      return DEFAULT_COMMUNITY_IMAGE_5;
    default:
      return DEFAULT_COMMUNITY_IMAGE_1;
  }
};

export const DEFAULT_COMMUNITY_BANNER_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/png/default-background-1.png';
