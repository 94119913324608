import React, { useEffect, useState } from 'react';
import LoginForm from '@/pages/login/components/LoginForm';
import style from './LoginPage.module.scss';
import NextImage from '@/components/common/NextImage';
import { NO_COMMUNITY_ERROR, sideImageData } from './constants';
import { useAdminAuthContext } from '@/contexts/AdminAuthContext';
import { NoCommunityModal } from '@/pages/login/components/NoCommunityModal/NoCommunityModal';
import { useRouter } from 'next/router';
import { sendLoginDataToAnalytics } from './utils';
import PortalPagesMetaTags from '@/components/common/PortalPagesMetaTags';
import { CM_PORTAL_HOMEPAGE_ROUTE } from '@/utility/routesHelper';

const LoginPage = () => {
  const {
    loginError,
    isLoading,
    login,
    isUserLoggedIn,
    isDemoUser,
    handleSocialAuth,
    socialLoginError
  } = useAdminAuthContext();
  const router = useRouter();

  const className = 'c-LoginPage';
  const [showNoCommunityModal, setShowNoCommunityModal] = useState(false);

  const handleLogin = async (email, password) => {
    const isCommunityAdmin = true;
    const redirectPostLogin = true;
    const communityMandatory = true;

    const res = await login(
      email,
      password,
      isCommunityAdmin,
      redirectPostLogin,
      communityMandatory
    );

    if (!res || res?.error) return;

    sendLoginDataToAnalytics(res?.user);
  };

  const handleSocialAuthMiddleware = (...args) => {
    console.log('>> handleSocialAuthMiddleware args', args);
    handleSocialAuth(...args);
    // TODO: Track Social Login here. Find a way to get user email
    // console.log('>> trac args pre ', args);
    // console.log('>>> trac args ', args);
    // sendLoginDataToAnalytics(args[0]);
  };

  // redirect to portal if admin is cm is already logged in
  useEffect(() => {
    if (isUserLoggedIn && !isDemoUser)
      router.push(CM_PORTAL_HOMEPAGE_ROUTE);
  }, [isUserLoggedIn, isDemoUser, router]);

  useEffect(() => {
    if (
      loginError === NO_COMMUNITY_ERROR ||
      socialLoginError === NO_COMMUNITY_ERROR
    ) {
      setShowNoCommunityModal(true);
    }
  }, [loginError, socialLoginError]);

  return (
    <>
      <PortalPagesMetaTags />
      <div className="flex items-center h-screen bg-light-f6">
        <div className={style[className]}>
          <div className={style[`${className}__container`]}>
            <div className={style[`${className}__media`]}>
              <NextImage {...sideImageData} />
            </div>
            <div className={style[`${className}__form`]}>
              <LoginForm
                login={handleLogin}
                loginError={loginError}
                isLoading={isLoading}
                handleSocialAuth={handleSocialAuthMiddleware}
                socialLoginError={socialLoginError}
              />
            </div>
          </div>
        </div>
        {showNoCommunityModal && (
          <NoCommunityModal
            onClose={() => setShowNoCommunityModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default LoginPage;
