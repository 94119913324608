import * as yup from 'yup';

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be in a valid format like “name@example.com”')
    .required('Email is required.'),
  password: yup.string().required('Password is required.')
});

export const shortLogoImageData = {
  alt: 'logo',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/icons/nas_academy_icon_dark.svg',
    width: 32,
    height: 32
  },
  desktopImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/icons/nas_academy_icon_dark.svg',
    width: 32,
    height: 32
  }
};
